import React from 'react';

import type { HoldEndEvent } from '@egjs/react-flicking';

import { Gallery } from '~/components/Gallery/Gallery';
import { GallerySliderPanelImage } from '~/components/Gallery/GallerySliderPanelImage';
import { galleryProps } from '~/components/Gallery/Gallery.constants';
import { DIRECTION } from '~/components/Slider/Slider.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { s7ImagePath } from '~/global/global.constants';

import type { GallerySliderPanelImageData } from '~/components/Gallery/Gallery.types';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

import styles from '~/locations/Components/StoreGallery.module.scss';

export const StoreGallery = ({
	galleryItems = [],
	storeName = '',
}) => {
	const {
		linkEventStore = {},
		magicModal = {},
	} = useGlobalContext();

	const storeGalleryPanels: GallerySliderPanelImageData[] = galleryItems.map(({
		caption,
		image: {
			altText,
			height,
			src,
			width,
		},
	}) => {
		const aspectRatio = width / height;

		return {
			SliderPanelComponent: GallerySliderPanelImage,
			sliderPanelComponentProps: {
				aspectRatio,
				caption,
				imageProps: {
					alt: altText,
					src: `${s7ImagePath}/${src}`,
				},
				width,
			},
		};
	});

	const lastIndex = storeGalleryPanels.length - 1;

	const { sliderProps } = galleryProps;

	const trLinkEventCompType = 'store gallery';

	const linkEventTrackingData = {
		trLinkEventCompName: storeName,
		trLinkEventCompType,
		trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
	};

	function trackDotsNavigation({ index = 0 }) {
		linkEventStore.trackLinkEvent({
			...linkEventTrackingData,
			trLinkEventCompPosition: `${index + 1}:${galleryItems.length}`,
			trLinkEventName: 'dot image navigation',
		});
	}

	function trackSliderNavigation({
		direction = '',
		index = 0,
	}) {
		linkEventStore.trackLinkEvent({
			...linkEventTrackingData,
			trLinkEventCompPosition: `${index + 1}:${galleryItems.length}`,
			trLinkEventName: `navigate to ${direction === DIRECTION.NEXT ? 'next' : 'previous'} image`,
		});
	}

	function handleHoldEnd({ currentTarget }: HoldEndEvent) {
		currentTarget.once('changed', ({
			direction = '',
			index = 0,
			prevIndex = 0,
		}) => {
			const circularDirectionNext = prevIndex === lastIndex && index === 0 && DIRECTION.NEXT;

			const circularDirectionPrev = prevIndex === 0 && index === lastIndex && DIRECTION.PREV;

			const directionToUse = circularDirectionNext || circularDirectionPrev || direction;

			trackSliderNavigation({
				direction: directionToUse,
				index,
			});
		});
	}

	return (
		<div
			aria-label="Store media carousel"
			className={styles.StoreGallery}
			data-qa="store-gallery"
			data-tr-link-event-comp-type={trLinkEventCompType}
			role="region"
		>
			<p className="tw-sr-only">The following is a carousel of images of this store location. Use the previous and next buttons to navigate through the images.</p>
			<Gallery
				{...galleryProps}
				captionSizeXSonMobile
				magicModal={magicModal}
				showCaptionBelowImageSm
				sliderArrowOnClick={trackSliderNavigation}
				sliderDotsOnClick={trackDotsNavigation}
				sliderPanels={storeGalleryPanels}
				sliderProps={{
					...sliderProps,
					onHoldEnd: handleHoldEnd,
				}}
			/>
		</div>
	);
};
